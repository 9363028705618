<template>
  <div class="accounts-page container" v-if="currentAccount">
    <h1>Accounts</h1>
    <hr />

    <div class="d-flex align-items-center align-content-center mb-2">
      <button
        v-if="currentAccount.hasPermission('accounts.create')"
        class="account-create btn btn-primary float-right"
        @click="createAccount"
      >
        Create new
      </button>
    </div>

    <div>
      <input type="text" class="form-control search" placeholder="Search" v-model="search" @input="onSearch" />
      <ag-grid-vue
        class="ag-theme-material"
        style="height: 75vh"
        rowModelType="serverSide"
        :gridOptions="gridOptions"
        :getRowId="getRowId"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3';
import { mapGetters } from 'vuex';
import { getAccountList } from '@/api';
import ActionsCellRenderer from '@/components/ActionsCellRenderer';
import { dateTimeFormatter, debounce } from '@/utils';
import { accountService } from '@/modules/account/account.service';

export default {
  name: 'AccountsPage',
  components: { AgGridVue, ActionsCellRenderer },
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
  },
  data: () => ({
    gridApi: null,
    getRowId: params => `${params.data.id}`,
    gridOptions: {
      suppressContextMenu: true,
      suppressCellFocus: true,
      cacheBlockSize: 30,
      maxBlocksInCache: 5,
      rowBuffer: 0,
    },
    defaultColDef: {
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    search: '',
  }),
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;

      this.gridOptions.context = this;

      this.gridApi.setDefaultColDef(this.defaultColDef);
      this.gridApi.setColumnDefs([
        {
          field: 'login',
          headerName: 'Login',
        },
        {
          field: 'permissions',
        },
        {
          field: 'createdAt',
          headerName: 'Created',
          valueFormatter: params => (params.value ? dateTimeFormatter.format(new Date(params.value)) : '-'),
        },
        {
          field: 'updatedAt',
          headerName: 'Updated',
          valueFormatter: params => (params.value ? dateTimeFormatter.format(new Date(params.value)) : '-'),
        },
        {
          field: 'lastActiveAt',
          headerName: 'Last active',
          valueFormatter: params => (params.value ? dateTimeFormatter.format(new Date(params.value)) : '-'),
        },
        {
          field: 'actions',
          resizable: false,
          cellRenderer: 'ActionsCellRenderer',
          cellRendererParams: {
            actions: [
              {
                show: entity => !entity.deletedAt && this.currentAccount.hasPermission('vehicle-types.edit'),
                title: 'Edit',
                icon: 'fa-edit',
                onClick: id => this.editAccount(id),
              },
              {
                show: entity => !entity.deletedAt && this.currentAccount.hasPermission('vehicle-types.remove'),
                title: 'Delete',
                icon: 'fa-trash-alt',
                onClick: id => this.deleteAccount(id),
              },
            ],
          },
        },
      ]);

      this.resizeColumns();

      this.gridApi.setServerSideDatasource({
        parent: this,
        async getRows(params) {
          try {
            const startRowIndex = params.request.startRow;
            const endRowIndex = params.request.endRow;
            const expectedRowCount = endRowIndex - startRowIndex;
            const currentRowCount = params.api.getServerSideStoreState()[0]?.rowCount || 0;

            const response = await getAccountList(expectedRowCount, params.request.startRow, params.context.search || undefined);
            if (response.errors) {
              throw new Error(response.errors[0].message);
            }

            const accounts = response.data.accounts;
            const receivedCount = accounts.length;

            // Account list returns full list without pagination
            // let rowCount = 0;
            // if (endRowIndex <= currentRowCount) {
            //   rowCount = currentRowCount;
            // } else {
            //   rowCount = receivedCount < expectedRowCount ? startRowIndex + receivedCount : endRowIndex + 1;
            // }

            params.success({
              rowData: accounts,
              rowCount: receivedCount,
            });
          } catch (err) {
            console.error(err);
            params.fail();
          }
        },
      });

      this.storeSubscription = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'account/ADD_TO_LIST':
            this.gridApi.applyServerSideTransaction({
              add: [mutation.payload],
            });
            break;

          case 'account/UPDATE_IN_LIST':
            this.gridApi.applyServerSideTransaction({
              update: [mutation.payload],
            });
            break;

          case 'account/REMOVE_FROM_LIST':
            this.gridApi.applyServerSideTransaction({
              remove: [mutation.payload],
            });
            break;

          default:
            break;
        }
      });
    },

    resizeColumns() {
      this.gridApi.sizeColumnsToFit({
        columnLimits: [{ key: 'actions', maxWidth: 120 }],
      });
    },

    createAccount() {
      this.$router.push({ name: 'account-create' });
    },

    editAccount(accountId) {
      this.$router.push({ name: 'account-edit', params: { id: accountId } });
    },

    async deleteAccount(accountId) {
      try {
        await accountService.remove(accountId);
        this.$toast.success('Account removed.', { duration: 2000 });
      } catch (err) {
        console.error(err);
        this.$toast.error(err.toString(), { duration: 2000 });
      }
    },

    onSearch() {
      this.gridApi.refreshServerSide({ purge: true });
    },
  },
  mounted() {
    this.onSearch = debounce(this.onSearch.bind(this), 500);

    this.resizeColumns = this.resizeColumns.bind(this);
    window.addEventListener('resize', this.resizeColumns);
  },
  unmounted() {
    this.storeSubscription?.();
    window.removeEventListener('resize', this.resizeColumns);
  },
};
</script>

<style scoped>
.accounts-page {
  width: 100%;
  padding: 1rem;
}

.accounts-page table {
  text-align: center;
}

.account-create {
  margin: 0.2em;
}

.account i {
  margin: 0 0.4em;
  cursor: pointer;
}

.permission {
  margin: 0 0.2em;
}
</style>
