<template>
  <div class="vehicles-page" v-if="currentAccount">
    <h1>Vehicles</h1>
    <hr />

    <div class="d-flex align-items-center align-content-center mb-2">
      <button v-if="currentAccount.hasPermission('vehicles.create')" class="vehicle-create btn btn-primary" @click="createVehicle">
        Create new
      </button>

      <div class="ms-3 form-check">
        <label for="all" class="form-check-label">All ({{ counters?.all ?? '-' }})</label>
        <input type="checkbox" class="form-check-input" id="all" v-model="all" />
      </div>

      <div class="ms-3 form-check">
        <label for="active" class="form-check-label">Active ({{ counters?.active ?? '-' }})</label>
        <input type="checkbox" class="form-check-input" id="active" v-model="filter.active" />
      </div>

      <div class="ms-3 form-check">
        <label for="inactive" class="form-check-label">Inactive ({{ counters?.inactive ?? '-' }})</label>
        <input type="checkbox" class="form-check-input" id="inactive" v-model="filter.inactive" />
      </div>

      <div class="ms-3 form-check">
        <label for="inService" class="form-check-label">Service ({{ counters?.inService ?? '-' }})</label>
        <input type="checkbox" class="form-check-input" id="inService" v-model="filter.inService" />
      </div>

      <div class="ms-3 form-check">
        <label for="notInService" class="form-check-label">Out of service ({{ counters?.notInService ?? '-' }})</label>
        <input type="checkbox" class="form-check-input" id="notInService" v-model="filter.notInService" />
      </div>

      <div class="ms-3 form-check">
        <label for="deletedOnly" class="form-check-label">Archived only ({{ counters?.deleted ?? '-' }})</label>
        <input type="checkbox" class="form-check-input" id="deletedOnly" v-model="deletedOnly" />
      </div>

      <i class="fa fa-clipboard-list expirations" v-if="vehiclesWithExpirations?.length" @click="openVehiclesWithExpirationsModalVisible">
        <span class="badge badge-danger">{{ vehiclesWithExpirations?.length | 0 }}</span>
      </i>

      <i class="fa fa-redo reset-service" title="Reset service" @click="resetService"></i>
    </div>

    <div>
      <div class="search-box">
        <input type="text" class="form-control search" placeholder="Search" v-model="search" @input="onSearch" />
        <i class="fa fa-cog search-settings" @click="openSearchSettingsModal"></i>
      </div>
      <ag-grid-vue
        class="ag-theme-material"
        style="height: 75vh"
        rowModelType="serverSide"
        :gridOptions="gridOptions"
        :getRowId="getRowId"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>

  <div class="modal" :class="{ show: modalVisible }" ref="trackModal" role="dialog" aria-modal="true" @keydown="onTrackModalKeydown" @click="onTrackModalClick">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>{{ currentVehicle?.unit }}</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="track">
            <div class="mb-3 col-md-12">
              <label for="currentVehicleLocation" class="form-label">Location</label>
              <div class="d-flex flex-wrap align-items-center">
                <SelectWithSearch
                  class="col-md-12"
                  inputId="currentVehicleLocation"
                  :inputValue="currentVehicleLocation?.postalCode ?? currentVehicleLocation?.formattedAddress"
                  :items="currentVehicleLocations"
                  :tabindex="1"
                  :handleBlur="true"
                  @search="onCurrentVehicleLocationSearch"
                  @clear="onCurrentVehicleLocationClear"
                />
                <span class="ms-3">{{ currentVehicleLocation?.formattedAddress }}</span>
              </div>
            </div>

            <div class="mb-3">
              <label for="currentVehicleOnRouteTill" class="form-label">Date</label>
              <div class="input-group">
                <input
                  type="datetime-local"
                  class="form-control col-md-10"
                  id="currentVehicleOnRouteTill"
                  :tabindex="3"
                  v-model="currentVehicleOnRouteTill"
                />
                <button class="btn btn-primary col-md-2" type="button" :tabindex="2" @click="setCurrentVehicleOnRouteTillToNow">Now</button>
              </div>
            </div>

            <div class="mb-3">
              <div class="btn btn-secondary me-2" :tabindex="4" @click="addLocalOrLongToNotes('Local')">Local</div>
              <div class="btn btn-secondary me-2" :tabindex="5" @click="addLocalOrLongToNotes('Long miles')">Long miles</div>
            </div>

            <div class="mb-3">
              <label for="currentVehicleNotes" class="form-label">Notes</label>
              <textarea type="text" class="form-control" id="currentVehicleNotes" :tabindex="6" v-model="currentVehicleNotes"></textarea>
            </div>

            <button class="btn btn-primary" type="button" :tabindex="7" @click="track">Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal expirations-window" :class="{ show: vehiclesWithExpirationsModalVisible }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Expirations</h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeVehiclesWithExpirationsModalVisible"
          ></button>
        </div>
        <div class="modal-body">
          <div v-for="vehicle of sortedVehiclesWithExpirations">
            <p>Unit: {{ vehicle.unit }}</p>
            <p>
              Registration:
              <template v-if="getExpirationDays(vehicle.registrationExpiresAt) > 0"
                >Expires by {{ getExpirationDays(vehicle.registrationExpiresAt) }} days</template
              >
              <template v-else>Expired</template>
            </p>
            <p>
              COI:
              <template v-if="getExpirationDays(vehicle.coiExpiresAt) > 0">{{ getExpirationDays(vehicle.coiExpiresAt) }} days</template>
              <template v-else>Expired</template>
            </p>
            <button class="btn btn-primary" @click="editVehicle(vehicle.id)">Open</button>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{ show: searchSettingsModalVisible }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Search settings</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeSearchSettingsModal"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="form-check">
              <label for="search-by-unit" class="form-check-label">By unit</label>
              <input type="checkbox" class="form-check-input" id="search-by-unit" v-model="searchSettings.unit" />
            </div>
            <div class="form-check">
              <label for="search-by-company-name" class="form-check-label">By company name</label>
              <input type="checkbox" class="form-check-input" id="search-by-company-name" v-model="searchSettings.mainContactCompanyName" />
            </div>
            <div class="form-check">
              <label for="search-by-location" class="form-check-label">By location</label>
              <input type="checkbox" class="form-check-input" id="search-by-location" v-model="searchSettings.location" />
            </div>
            <div class="form-check">
              <label for="search-by-main-contact-name" class="form-check-label">By main contact name</label>
              <input type="checkbox" class="form-check-input" id="search-by-main-contact-name" v-model="searchSettings.mainContactName" />
            </div>
            <div class="form-check">
              <label for="search-by-main-contact-phone" class="form-check-label">By main contact phone</label>
              <input type="checkbox" class="form-check-input" id="search-by-main-contact-phone" v-model="searchSettings.mainContactPhone" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3';
import { mapGetters } from 'vuex';
import {
  dateTimeFormatter,
  dateToInputDateTime,
  inputDateTimeToDate,
  isVehicleActiveByRules,
  isVehicleRegistrationExpiresAtLowerThan,
  isVehicleCoiExpiresAtLowerThan,
  debounce,
  hasFlag,
} from '@/utils';
import {
  getVehicleList,
  deleteVehicle,
  getLocationList,
  findVehicle,
  updateVehicle,
  restoreVehicle,
  getVehicleCounters,
  getVehiclesWithExpirations,
} from '@/api';
import { VehicleContactRole } from '@/types/vehicle-contact';
import ActionsCellRenderer from '@/components/ActionsCellRenderer';
import SelectWithSearch from '@/components/SelectWithSearch';

export default {
  name: 'VehiclesPage',
  components: { AgGridVue, ActionsCellRenderer, SelectWithSearch },
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
    sortedVehiclesWithExpirations() {
      return this.vehiclesWithExpirations.sort((a, b) => {
        const aRegistrationExpiredAtDate = new Date(a.registrationExpiresAt);
        const aCoiExpiredAtDate = new Date(a.coiExpiresAt);
        const aLowestDate = Math.min(aRegistrationExpiredAtDate, aCoiExpiredAtDate);

        const bRegistrationExpiredAtDate = new Date(b.registrationExpiresAt);
        const bCoiExpiredAtDate = new Date(b.coiExpiresAt);
        const bLowestDate = Math.min(bRegistrationExpiredAtDate, bCoiExpiredAtDate);

        return aLowestDate - bLowestDate;
      });
    },
    expiredVehicles() {
      const now = new Date();

      return this.vehiclesWithExpirations.filter(
        i => isVehicleRegistrationExpiresAtLowerThan(i, now) || isVehicleCoiExpiresAtLowerThan(i, now),
      );
    },
    expiresByThreeDaysVehicles() {
      const now = new Date();
      const threeDays = new Date(now);
      threeDays.setUTCDate(threeDays.getUTCDate() + 3);

      return this.vehiclesWithExpirations.filter(
        i =>
          (!isVehicleRegistrationExpiresAtLowerThan(i, now) && isVehicleRegistrationExpiresAtLowerThan(i, threeDays)) ||
          (!isVehicleCoiExpiresAtLowerThan(i, now) && isVehicleCoiExpiresAtLowerThan(i, threeDays)),
      );
    },
    expiresByFiveDaysVehicles() {
      const now = new Date();
      const threeDays = new Date(now);
      threeDays.setUTCDate(threeDays.getUTCDate() + 3);
      const fiveDays = new Date(now);
      fiveDays.setUTCDate(fiveDays.getUTCDate() + 5);

      return this.vehiclesWithExpirations.filter(
        i =>
          (!isVehicleRegistrationExpiresAtLowerThan(i, threeDays) && isVehicleRegistrationExpiresAtLowerThan(i, fiveDays)) ||
          (!isVehicleCoiExpiresAtLowerThan(i, threeDays) && isVehicleCoiExpiresAtLowerThan(i, fiveDays)),
      );
    },
    expiresBySevenDaysVehicles() {
      const now = new Date();
      const fiveDays = new Date(now);
      fiveDays.setUTCDate(fiveDays.getUTCDate() + 5);
      const sevenDays = new Date(now);
      sevenDays.setUTCDate(sevenDays.getUTCDate() + 7);

      return this.vehiclesWithExpirations.filter(
        i =>
          (!isVehicleRegistrationExpiresAtLowerThan(i, fiveDays) && isVehicleRegistrationExpiresAtLowerThan(i, sevenDays)) ||
          (!isVehicleCoiExpiresAtLowerThan(i, fiveDays) && isVehicleCoiExpiresAtLowerThan(i, sevenDays)),
      );
    },
  },
  data: () => ({
    gridApi: null,
    getRowId: params => `${params.data.id}`,
    gridOptions: {
      suppressContextMenu: true,
      suppressCellFocus: true,
      enableBrowserTooltips: true,
      cacheBlockSize: 30,
      maxBlocksInCache: 5,
      rowBuffer: 0,
      getRowClass: params => {
        if (params.data?.onHoldTill) {
          return 'on-hold';
        }
      },
      onCellClicked: async event => {
        if (typeof event.colDef.onClick === 'function') {
          let clickResult = event.colDef.onClick(event);
          if (clickResult instanceof Promise) {
            clickResult = await clickResult;
          }

          if (clickResult === true) {
            return;
          }
        }

        if (!event.colDef.copyOnClick) {
          return;
        }

        var textArea = document.createElement('textarea');
        textArea.value = event.value;

        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          if (document.execCommand('copy')) {
            event.context.$toast.success(`${event.colDef.headerName} copied`);
          }
        } catch (err) {
          event.context.$toast.error(err.message);
        }

        document.body.removeChild(textArea);
      },
      onColumnMoved: async event => {
        event.context.saveColumnsState(event.columnApi);
      },
      onColumnVisible: async event => {
        event.context.saveColumnsState(event.columnApi);
      },
      onColumnResized: async event => {
        if (!event.finished) {
          return;
        }

        event.context.saveColumnsState(event.columnApi);
      },
      onColumnPinned: async event => {
        event.context.saveColumnsState(event.columnApi);
      },
    },
    defaultColDef: {
      resizable: true,
      copyOnClick: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    search: '',
    filter: {
      active: false,
      inactive: false,
      inService: false,
      notInService: false,
    },
    all: true,
    deletedOnly: false,

    modalVisible: false,
    trackModalCurrentIndex: -1,

    counters: null,
    vehiclesWithExpirations: [],

    currentVehicle: null,

    currentVehicleLocations: [],
    currentVehicleLocation: null,

    currentVehicleOnRouteTill: null,
    currentVehicleNotes: null,

    vehiclesWithExpirationsModalVisible: false,

    searchSettingsModalVisible: false,
    searchSettings: {
      unit: true,
      location: true,
      mainContactName: true,
      mainContactPhone: true,
      mainContactCompanyName: true,
    },

    isVehicleRegistrationExpiresAtLowerThan,
  }),
  methods: {
    getExpirationDays(date) {
      return Math.ceil((new Date(date) - Date.now()) / 1000 / 60 / 60 / 24);
    },

    onGridReady(params) {
      getVehicleCounters()
        .then(res => {
          if (!res?.data?.vehicleCounters) {
            return;
          }

          this.counters = res.data.vehicleCounters;
        })
        .catch(err => console.error(err));

      getVehiclesWithExpirations()
        .then(res => {
          if (!res?.data?.vehiclesWithExpirations) {
            return;
          }

          this.vehiclesWithExpirations = res.data.vehiclesWithExpirations;
        })
        .catch(err => console.error(err));

      this.gridApi = params.api;

      this.gridOptions.context = this;

      this.gridApi.setDefaultColDef(this.defaultColDef);

      this.setColumns();
      this.applyColumnsState(params.columnApi);
      this.applyFilterState();

      this.gridApi.setServerSideDatasource({
        parent: this,
        async getRows(params) {
          try {
            const startRowIndex = params.request.startRow;
            const endRowIndex = params.request.endRow;
            const expectedRowCount = endRowIndex - startRowIndex;
            const currentRowCount = params.api.getServerSideStoreState()[0]?.rowCount || 0;

            const sortBy = params.request.sortModel.map(i => ({ field: i.colId, order: i.sort.toUpperCase() }));

            const searchSettings = params.context.searchSettings;
            const searchByFields = [];

            if (searchSettings.unit) {
              searchByFields.push('unit');
            }

            if (searchSettings.location) {
              searchByFields.push('location.formattedAddress');
            }

            if (searchSettings.mainContactName) {
              searchByFields.push('mainContact.person.name');
            }

            if (searchSettings.mainContactPhone) {
              searchByFields.push('mainContact.person.phone');
            }

            if (searchSettings.mainContactCompanyName) {
              searchByFields.push('mainContact.person.companyName');
            }

            const response = await getVehicleList(
              expectedRowCount,
              params.request.startRow,
              params.context.search || undefined,
              sortBy.length ? sortBy : undefined,
              params.context.deletedOnly,
              params.context.filter,
              searchByFields,
            );

            if (response.errors) {
              throw new Error(response.errors[0].message);
            }

            const vehicles = response.data.vehicles;
            params.context.currentVehicle = vehicles[0];

            const receivedCount = vehicles.length;

            let rowCount = 0;
            if (endRowIndex <= currentRowCount) {
              rowCount = currentRowCount;
            } else {
              rowCount = receivedCount < expectedRowCount ? startRowIndex + receivedCount : endRowIndex + 1;
            }

            params.success({
              rowData: vehicles,
              rowCount,
            });
          } catch (err) {
            console.error(err);
            params.fail();
          }
        },
      });

      this.storeSubscription = this.$store.subscribe(async (mutation, state) => {
        switch (mutation.type) {
          case 'vehicle/ADD_TO_LIST':
            if (this.deletedOnly) {
              this.gridApi.applyServerSideTransaction({
                remove: [mutation.payload],
              });
            } else {
              this.gridApi.applyServerSideTransaction({
                add: [mutation.payload],
              });
            }

            break;

          case 'vehicle/UPDATE_IN_LIST':
            this.gridApi.applyServerSideTransaction({
              update: [mutation.payload],
            });

            break;

          case 'vehicle/REMOVE_FROM_LIST':
            if (this.deletedOnly) {
              this.gridApi.applyServerSideTransaction({
                add: [mutation.payload],
              });
            } else {
              this.gridApi.applyServerSideTransaction({
                remove: [mutation.payload],
              });
            }

            break;

          case 'vehicle/SET_COUNTERS': {
            this.counters = mutation.payload;
          }

          case 'person/UPDATE_IN_LIST': {
            const person = mutation.payload;
            const vehiclesWithPerson = this.gridApi.getRenderedNodes?.().filter(i => i.data?.contacts.find(j => j.personId === person.id));

            const updatedVehicles = await Promise.all(
              vehiclesWithPerson.map(async vehicle => {
                try {
                  const response = await findVehicle(vehicle.data.id);

                  if (response?.errors) {
                    return;
                  }

                  return response.data.vehicle;
                } catch (err) {
                  return null;
                }
              }),
            );

            this.gridApi.applyServerSideTransaction({
              update: updatedVehicles.filter(i => !!i),
            });

            break;
          }

          case 'equipment/UPDATE_IN_LIST': {
            const equipment = mutation.payload;
            const vehiclesWithEquipment = this.gridApi
              .getRenderedNodes?.()
              .filter(i => i.data?.equipments.find(j => j.equipmentId === equipment.id));

            const updatedVehicles = await Promise.all(
              vehiclesWithEquipment.map(async vehicle => {
                try {
                  const response = await findVehicle(vehicle.data.id);

                  if (response?.errors) {
                    return;
                  }

                  return response.data.vehicle;
                } catch (err) {
                  return null;
                }
              }),
            );

            this.gridApi.applyServerSideTransaction({
              update: updatedVehicles.filter(i => !!i),
            });

            break;
          }

          default:
            break;
        }
      });
    },

    setColumns() {
      this.gridApi.setColumnDefs([
        {
          field: 'actions',
          headerName: 'Actions',
          width: 100,
          cellStyle: { textAlign: 'center' },
          cellRenderer: 'ActionsCellRenderer',
          cellRendererParams: {
            actions: [
              {
                show: entity => !entity.deletedAt && this.currentAccount.hasPermission('vehicles.track'),
                title: 'Track',
                icon: 'fa-map-marker-alt',
                onClick: id => this.trackVehicle(id),
              },
              {
                show: entity => !entity.deletedAt && this.currentAccount.hasPermission('vehicles.edit'),
                title: 'Edit',
                icon: 'fa-edit',
                onClick: id => this.editVehicle(id),
              },
              {
                show: entity => !entity.deletedAt && this.currentAccount.hasPermission('vehicles.remove'),
                title: 'Delete',
                icon: 'fa-trash-alt',
                onClick: id => this.deleteVehicle(id),
              },
              {
                show: entity => !!entity.deletedAt && this.currentAccount.hasPermission('vehicles.remove'),
                title: 'Restore',
                icon: 'fa-trash-restore-alt',
                onClick: id => this.restoreVehicle(id),
              },
            ],
          },
          copyOnClick: false,
        },
        {
          field: 'isInService',
          headerName: 'Service',
          cellRenderer: params => {
            const { isInService, isActive } = params.data;
            const isActiveByRules = isVehicleActiveByRules(params.data);

            const active = `<i class="status fas fa-check${!isActiveByRules ? ' disabled' : ''}"></i>`;
            const inactive = `<i class="status fas fa-times${!isActiveByRules ? ' disabled' : ''}"></i>`;

            if (!isInService) {
              return inactive;
            }

            if (!isActive) {
              return inactive;
            }

            return isActiveByRules ? active : inactive;
          },
          width: 90,
          cellStyle: { textAlign: 'center' },
          copyOnClick: false,
          onClick: async event => {
            const { id, isInService, isActive } = event.data;

            if (isActive && isVehicleActiveByRules(event.data)) {
              const response = await updateVehicle(id, { isInService: !isInService });
              if (response.errors) {
                this.$toast.error(response.errors[0].message);
              } else {
                this.gridApi.applyServerSideTransaction({
                  update: [response.data.updateVehicle],
                });
                this.$toast.success('Vehicle was updated.', { duration: 2000 });
              }
            }
          },
        },
        {
          field: 'isActive',
          headerName: 'Active',
          cellRenderer: params => {
            const { isActive } = params.data;
            const hasPermission = this.currentAccount.hasPermission('vehicles.change-active');
            const isActiveByRules = isVehicleActiveByRules(params.data);

            const active = `<i class="status fas fa-check${!isActiveByRules || !hasPermission ? ' disabled' : ''}"></i>`;
            const inactive = `<i class="status fas fa-times${!isActiveByRules || !hasPermission ? ' disabled' : ''}"></i>`;

            if (!isActive) {
              return inactive;
            }

            return isActiveByRules ? active : inactive;
          },
          width: 90,
          cellStyle: { textAlign: 'center' },
          copyOnClick: false,
          onClick: async event => {
            const { id, isActive } = event.data;

            if (!this.currentAccount.hasPermission('vehicles.change-active')) {
              return;
            }

            if (isVehicleActiveByRules(event.data)) {
              const response = await updateVehicle(id, { isActive: !isActive });
              if (response.errors) {
                this.$toast.error(response.errors[0].message);
              } else {
                this.gridApi.applyServerSideTransaction({
                  update: [response.data.updateVehicle],
                });
                this.gridApi.refreshCells({ rowNodes: [event.node], force: true });
                this.$toast.success('Vehicle was updated.', { duration: 2000 });
              }
            }
          },
        },
        {
          field: 'unit',
          headerName: 'Unit',
          width: 120,
          sortable: true,
        },
        {
          field: 'location.formattedAddress',
          headerName: 'Location',
          valueFormatter: params => params.value || '-',
          tooltipValueGetter: params => params.data.location?.formattedAddress,
        },
        {
          field: 'logo',
          headerName: 'Logo',
          valueGetter: params => {
            const logos = [];

            const { hasMmmLogo, hasTurboLogo } = params.data;

            if (hasTurboLogo) {
              logos.push('Turbo');
            }

            if (hasMmmLogo) {
              logos.push('MMM');
            }

            return logos.length ? logos.join(', ') : '-';
          },
        },
        {
          field: 'type.name',
          headerName: 'Type',
          valueFormatter: params => params.value || '-',
        },
        {
          field: 'mainContact.person.companyName',
          headerName: 'Company name',
          sortable: true,
          valueGetter: params => params.data.contacts.find(i => i.isMain)?.person?.companyName,
          valueFormatter: params => params.value || '-',
        },
        {
          field: 'mainContact.person.name',
          headerName: 'Name',
          valueGetter: params => params.data.contacts.find(i => i.isMain)?.person?.name,
          cellRenderer: params => {
            const mainContact = params.data.contacts.find(i => i.isMain);
            if (!mainContact?.person) {
              return '-';
            }

            const roles = mainContact.roles;

            let result = mainContact.person.name;

            if (hasFlag(roles, VehicleContactRole.OWNER)) {
              // result += `<svg class="contact-owner" title="Owner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>`;
            } else if (hasFlag(roles, VehicleContactRole.DRIVER)) {
              result += `<svg class="contact-driver" title="Driver" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g><path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,2a8,8,0,0,1,7.38,4.92A29.93,29.93,0,0,0,12,8a29.63,29.63,0,0,0-7.4.94A8,8,0,0,1,12,4ZM4,12.67l1.11-.13A4.38,4.38,0,0,1,10,16.89v2.85A8,8,0,0,1,4,12.67Zm10,7.07V16.89a4.38,4.38,0,0,1,4.86-4.35l1.11.13A8,8,0,0,1,14,19.74Z"></path></g></svg>`;
            } else if (hasFlag(roles, VehicleContactRole.DISPATCHER)) {
              result += `<svg class="contact-dispatcher" title="Dispatcher" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"/></svg>`;
            }

            return result;
          },
        },
        {
          field: 'mainContact.person.phone',
          headerName: 'Phone',
          valueGetter: params => params.data.contacts.find(i => i.isMain)?.person?.phone,
          valueFormatter: params => params.value || '-',
        },
        {
          field: 'dimensions',
          headerName: 'Dimensions',
          valueGetter: params => {
            const { width, height, length, doorWidth, doorHeight } = params.data;

            return `${length | 0}/${width | 0}/${height | 0} (${doorWidth | 0}/${doorHeight | 0})`;
          },
        },
        {
          field: 'capacity',
          headerName: 'Capacity',
          valueFormatter: params => params.value | 0,
        },
        {
          field: 'equipment',
          headerName: 'Equipment',
          valueGetter: params => params.data.equipments.map(i => i.equipment.name).join(', '),
          tooltipValueGetter: params => params.data.equipments.map(i => i.equipment.name).join(', '),
        },
        {
          field: 'onHoldTill',
          headerName: 'On hold',
          valueFormatter: params => (params.value ? dateTimeFormatter.format(new Date(params.value)) : '-'),
        },
        {
          field: 'onRouteTill',
          headerName: 'Date and time',
          valueFormatter: params => (params.value ? dateTimeFormatter.format(new Date(params.value)) : '-'),
          cellRenderer: params => {
            const { id, onRouteTill } = params.data;
            const value = onRouteTill ? dateTimeFormatter.format(new Date(onRouteTill)) : '-';

            return value + `<i class="update-to-now far fa-clock" data-vehicle="${id}"></i>`;
          },
          onClick: event => {
            const originEvent = event.event;
            if (!!originEvent.target.closest('.update-to-now')) {
              return true;
            }
          },
        },
        {
          field: 'notes',
          headerName: 'Notes',
          valueFormatter: params => params.value || '-',
          tooltipValueGetter: params => params.data.notes,
        },
        {
          field: 'plateNumber',
          headerName: 'Plate',
          valueFormatter: params => params.value || '-',
        },
      ]);
    },

    saveColumnsState(columnApi) {
      const state = columnApi.getColumnState();
      localStorage.setItem('vehicle-table-preset', JSON.stringify(state));
    },

    applyColumnsState(columnApi) {
      const state = localStorage.getItem('vehicle-table-preset');
      if (!state) {
        return;
      }
      columnApi.applyColumnState({ state: JSON.parse(state), applyOrder: true });
    },

    saveFilterState() {
      localStorage.setItem('vehicle-table-filter', JSON.stringify(this.filter));
    },

    applyFilterState() {
      const filter = localStorage.getItem('vehicle-table-filter');
      if (!filter) {
        return;
      }

      this.filter = JSON.parse(filter);
    },

    createVehicle() {
      this.$router.push({ name: 'vehicle-create' });
    },

    editVehicle(id) {
      this.$router.push({ name: 'vehicle-edit', params: { id } });
    },

    async deleteVehicle(id) {
      try {
        const response = await deleteVehicle(id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Vehicle was deleted.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },

    async restoreVehicle(id) {
      try {
        const response = await restoreVehicle(id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Vehicle was restored.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },

    onSearch() {
      this.gridApi.refreshServerSide({ purge: true });
    },

    openModal() {
      this.modalVisible = true;
      requestAnimationFrame(() => {
        this.$refs.trackModal.setAttribute('tabindex', -1);
        this.$refs.trackModal.focus();
      });
    },

    closeModal() {
      this.modalVisible = false;

      this.trackModalCurrentIndex = -1;

      this.currentVehicle = null;
      this.currentVehicleLocations = [];
      this.currentVehicleLocation = null;
    },

    async trackVehicle(id) {
      const response = await findVehicle(id);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.currentVehicle = response.data.vehicle;
      this.currentVehicleLocation = this.currentVehicle.location;
      this.currentVehicleOnRouteTill = this.currentVehicle.onRouteTill ? dateToInputDateTime(this.currentVehicle.onRouteTill) : null;
      this.currentVehicleNotes = this.currentVehicle.notes;

      const dateInput = this.$refs.trackModal.querySelector('#currentVehicleOnRouteTill');
      if (dateInput) {
        const now = Date.now();
        dateInput.min = dateToInputDateTime(new Date(now));
        dateInput.max = dateToInputDateTime(new Date(now + 1000 * 60 * 60 * 24 * 6));
      }

      this.openModal();
    },

    async addLocalOrLongToNotes(value) {
      const variants = ['Local', 'Long miles'];

      if (!this.currentVehicleNotes) {
        this.currentVehicleNotes = value + '\n';
        return;
      }

      if (!variants.some(i => this.currentVehicleNotes.includes(i + '\n'))) {
        this.currentVehicleNotes = value + '\n' + this.currentVehicleNotes;
        return;
      }

      variants.forEach(i => {
        this.currentVehicleNotes = this.currentVehicleNotes.replace(i, value);
      });
    },

    async track() {
      try {
        if (!this.currentVehicle) {
          throw new Error('Vehicle not selected.');
        }

        const data = { isInService: true };

        const locationId = this.currentVehicleLocation?.id ?? null;
        if (locationId !== this.currentVehicle.locationId) {
          data.locationId = locationId;
        }

        const onRouteTill = this.currentVehicleOnRouteTill ? inputDateTimeToDate(this.currentVehicleOnRouteTill) : null;
        if (onRouteTill !== this.currentVehicle.onRouteTill) {
          data.onRouteTill = onRouteTill;
        }

        if (this.currentVehicleNotes !== this.currentVehicle.notes) {
          data.notes = this.currentVehicleNotes;
        }

        const response = await updateVehicle(this.currentVehicle.id, data);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Vehicle was updated.', { duration: 2000 });
        this.closeModal();
      } catch (err) {
        console.error(err);
        this.$toast.error(err.message);
      }
    },

    openVehiclesWithExpirationsModalVisible() {
      requestAnimationFrame(() => {
        this.vehiclesWithExpirationsModalVisible = true;
      });
    },

    closeVehiclesWithExpirationsModalVisible() {
      requestAnimationFrame(() => {
        this.vehiclesWithExpirationsModalVisible = false;
      });
    },

    async onCurrentVehicleLocationSearch(search) {
      const response = await getLocationList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.currentVehicleLocations = response.data.locations.map(i => ({
        title: i.formattedAddress,
        onClick: () => this.setCurrentVehicleLocation(i),
      }));
    },

    onCurrentVehicleLocationClear() {
      this.setCurrentVehicleLocation(null);
    },

    setCurrentVehicleLocation(location) {
      this.currentVehicleLocation = location;
    },

    setCurrentVehicleOnRouteTillToNow() {
      this.currentVehicleOnRouteTill = dateToInputDateTime(new Date());
    },

    openSearchSettingsModal() {
      requestAnimationFrame(() => {
        this.searchSettingsModalVisible = true;
      });
    },

    closeSearchSettingsModal() {
      requestAnimationFrame(() => {
        this.searchSettingsModalVisible = false;
      });
    },

    saveSearchSettings() {
      localStorage.setItem('vehicle-table-search-settings', JSON.stringify(this.searchSettings));
    },

    loadSearchSettings() {
      const searchSettings = localStorage.getItem('vehicle-table-search-settings');
      if (!searchSettings) {
        return;
      }

      this.searchSettings = JSON.parse(searchSettings);
    },

    resetService() {
      fetch(`/cronjobs/reset-vehicle-service?company=${this.currentAccount.companyId}&key=${process.env.VUE_APP_CRONJOBS_KEY}`)
        .then(response => response.text())
        .then(response => this.$toast.success(response))
        .catch(err => {
          console.error(err);
          this.$toast.error(err.message);
        });
    },

    onTrackModalKeydown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();

        if (event.shiftKey) {
          this.trackModalCurrentIndex--;
          if (this.trackModalCurrentIndex < 0) {
            this.trackModalCurrentIndex = 0;
          }
        } else {
          this.trackModalCurrentIndex++;
          if (this.trackModalCurrentIndex > 6) {
            this.trackModalCurrentIndex = 6;
          }
        }

        this.$refs.trackModal.querySelector(`[tabindex="${this.trackModalCurrentIndex + 1}"]`)?.focus();
      }

      if (event.key === 'Enter' && event.ctrlKey) {
        this.track();
      }
    },

    onTrackModalClick(event) {
      const closestTabIndex = event.target.closest('[tabindex]')?.getAttribute('tabindex') ?? null;
      if (closestTabIndex !== null) {
        this.trackModalCurrentIndex = +closestTabIndex;
      }
    },

    async updateToNow(vehicleId) {
      const now = dateToInputDateTime(new Date());

      const response = await updateVehicle(vehicleId, { onRouteTill: inputDateTimeToDate(now) });
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      this.$toast.success('Date and time was updated.', { duration: 2000 });
    },

    updateToNowClick(event) {
      const elem = event.target.closest('.update-to-now');
      if (!elem) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      const vehicleId = elem.getAttribute('data-vehicle');
      this.updateToNow(vehicleId).catch(err => this.$toast.error(err.message));
    },
  },
  mounted() {
    this.onSearch = debounce(this.onSearch.bind(this), 500);

    document.addEventListener('click', this.updateToNowClick);

    this.loadSearchSettings();
  },
  unmounted() {
    document.removeEventListener('click', this.updateToNowClick);

    this.storeSubscription?.();
  },
  watch: {
    filter: {
      handler(value) {
        if (Object.values(value).some(i => i === true)) {
          this.all = false;
        }

        this.saveFilterState();
        this.gridApi.refreshServerSide({ purge: true });
      },
      deep: true,
    },
    searchSettings: {
      handler() {
        this.saveSearchSettings();
      },
      deep: true,
    },
    all: {
      handler(value) {
        if (!value) {
          return;
        }

        const result = {};
        Object.keys(this.filter).forEach(i => (result[i] = false));
        this.filter = result;
      },
    },
    deletedOnly: {
      handler(value) {
        this.gridApi.refreshServerSide({ purge: true });
      },
    },
  },
};
</script>

<style scoped>
.vehicles-page {
  width: 100%;
  padding: 1rem;
}

.vehicle-create {
  margin: 0.2em;
}

.vehicles-page .search-box {
  position: relative;
}

.vehicles-page .search {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  line-height: 2.2;
}

.vehicles-page .search-settings {
  position: absolute;
  font-size: 1.6em;
  right: 0.4em;
  top: 50%;
  color: #1a1a1a;
  transform: translateY(-50%);
  cursor: pointer;
}

.expirations {
  font-size: 1.5rem;
  margin-left: auto;
  margin-right: 1.5rem;
  cursor: pointer;
  position: relative;
}

.expirations .badge-danger {
  background-color: var(--bs-danger);
  position: absolute;
  font-size: 0.4em;
  right: -0.3rem;
  bottom: -0.3rem;
}

.expirations-window .modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

.reset-service {
  font-size: 1.5rem;
  margin-right: 1.5rem;
  cursor: pointer;
  position: relative;
}

/* #track-modal-focus {
  opacity: 0;
  filter: alpha(opacity=0);
} */
</style>

<style>
.on-hold {
  background-color: #ff00002f;
}

.on-hold:hover {
  background-color: #ff00003f;
}

.ag-row-hover.on-hold:before {
  background-color: transparent;
}

.contact-owner,
.contact-driver,
.contact-dispatcher {
  width: 1.5rem;
  fill: var(--primary-text-color);
  margin-left: 0.2rem;
}

.vehicles-page .status {
  cursor: pointer;
}

.vehicles-page .status.disabled {
  cursor: not-allowed;
}

.vehicles-page .status.fa-check {
  color: lime;
}

.vehicles-page .status.fa-times {
  color: red;
}

.update-to-now {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
