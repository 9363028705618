<template>
  <div class="map-page">
    <div class="map-header">
      <div class="map-header__search">
        <div class="map-header__button" @click="onRadiusClick">
          <template v-if="!radiusEditing"> {{ radius }} miles </template>
          <input
            class="map-header__input"
            ref="radiusInput"
            v-else
            type="text"
            :value="radius"
            @input="onRadiusChange"
            @blur="onRadiusBlur"
          />
        </div>

        <SelectWithSearch
          v-if="searchMode === 'location'"
          class="search"
          :items="searchLocations"
          icon="fa fa-map"
          :clearOnFocus="true"
          @search="onSearchLocationSearch"
          @clear="onSearchLocationClear"
        >
          <div class="search-mode" @click="changeSearchMode('vehicle')"></div>
        </SelectWithSearch>
        <SelectWithSearch
          v-else
          class="search"
          :items="searchVehicles"
          icon="fa fa-car"
          @search="onSearchVehicleSearch"
          @clear="onSearchVehicleClear"
        >
          <div class="search-mode" @click="changeSearchMode('location')"></div>
        </SelectWithSearch>

        <!-- <div class="map-header__button" @click="openSettingsWindow">Settings</div> -->
        <MapSettings @settingsChanged="onMapSettingsChange" />
      </div>

      <div class="map-header__timezones">
        <div class="timezone">
          PST
          {{
            currentTime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', timeZone: 'America/Los_Angeles' })
          }}
        </div>
        <div class="timezone">
          MST
          {{ currentTime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', timeZone: 'America/Denver' }) }}
        </div>
        <div class="timezone">
          CST
          {{ currentTime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', timeZone: 'America/Chicago' }) }}
        </div>
        <div class="timezone">
          EST
          {{ currentTime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', timeZone: 'America/New_York' }) }}
        </div>
      </div>
    </div>

    <div class="google-map" ref="googleMap"></div>

    <div class="info-label" :class="{ opened: isInfoOpened && currentVehicleDistances }" @click="!isInfoOpened ? openInfo() : closeInfo()">
      Found {{ currentVehicleDistances?.length ?? '-' }} vehicles
    </div>
    <div class="info-window" :class="{ opened: isInfoOpened && currentVehicleDistances }">
      <div v-for="item in currentVehicleDistances" :key="item.vehicle.id" class="info-section">
        <div class="block block-1">
          <div v-if="item.vehicle.hasTurboLogo" class="turbo-logo"><img src="/Turbo_XPD_logo.png" /></div>
          <div v-if="item.vehicle.hasMmmLogo" class="mmm-logo"><img src="/MMM_Express_logo.png" /></div>
          <div
            class="unit"
            :class="{
              available: getVehicleAvailability(item.vehicle),
              unavailable: !getVehicleAvailability(item.vehicle),
            }"
            @click="openReservationWindow(item.vehicle)"
          >
            {{ item.vehicle.unit }}
          </div>

          <div class="contact-lang">{{ item.vehicle.contacts.find(i => i.isMain)?.person?.langs.join(', ') }}</div>
          <div class="contact-name">{{ item.vehicle.contacts.find(i => i.isMain)?.person?.name }}</div>
          <!-- <div class="contact-status">{{ getStatusTranslate(item.vehicle.contacts.find(i => i.isMain)?.person?.status) }}</div> -->
          <div class="driver-status">{{ getVehicleDriverStatus(item.vehicle) }}</div>
          <div class="contact-phone">{{ item.vehicle.contacts.find(i => i.isMain)?.person?.phone }}</div>

          <div class="notes" :title="item.vehicle.notes">
            <textarea @change="e => updateVehicleNotes(item.vehicle, e)">{{ item.vehicle.notes || '' }}</textarea>
          </div>
        </div>

        <div class="block block-2">
          <div class="type">{{ item.vehicle.type.name }}</div>
          <div class="dimensions">
            {{ item.vehicle.length }}/{{ item.vehicle.width }}/{{ item.vehicle.height }} ({{ item.vehicle.doorWidth }}/{{
              item.vehicle.doorHeight
            }})
          </div>
          <div class="capacity">{{ item.vehicle.capacity }}</div>
          <div class="team">{{ item.vehicle.isTeam ? 'Team' : 'Solo' }}</div>
        </div>

        <div class="block block-3">
          <div class="distance">{{ item.distance }} miles</div>
        </div>

        <div class="block block-4">
          <div class="available-from" :class="{ red: item.vehicle.onRouteTill && new Date(item.vehicle.onRouteTill) > currentTime }">
            {{
              item.vehicle.onRouteTill
                ? new Date(item.vehicle.onRouteTill).toLocaleString('en-US', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour12: false,
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZone: 'America/New_York',
                  }) + ' EST'
                : ''
            }}
          </div>
          <div class="location">
            {{ item.vehicle.location?.formattedAddress.slice(0, -5) }}
          </div>
        </div>

        <div class="block block-5">
          <div v-for="(equipment, index) of item.vehicle.equipments.slice(0, 4)" :class="[`equipment-${index + 1}`]">
            {{ equipment.equipment.name }}
          </div>
        </div>

        <div class="block block-6">
          <div class="driver-info" title="Driver's Information" @click="openContactInfo(item)">
            <i class="fa fa-user"></i>
          </div>
          <div class="bid" title="Bid" @click="openBidInfo(item)"><i class="fa fa-clipboard-list"></i></div>
        </div>
      </div>
    </div>

    <div class="hints-label" :class="{ opened: isHintsOpened }" @click="!isHintsOpened ? openHints() : closeHints()">
      Hints
    </div>
    <div class="hints" :class="{ opened: isHintsOpened }">
      <div v-for="vehicleType of vehicleTypes" class="hint">
        <i :style="{ backgroundColor: vehicleType.color }"></i>{{ vehicleType.name }}
      </div>
    </div>

    <div
      class="my-reservations-label"
      :class="{ opened: isMyReservationsOpened && myReservations.length }"
      @click="!isMyReservationsOpened ? openMyReservations() : closeMyReservations()"
    >
      My {{ myReservations.length }} reservations
    </div>
    <div class="my-reservations" :class="{ opened: isMyReservationsOpened && myReservations.length }">
      <div v-for="reservation of myReservations" class="my-reservation mb-2">
        Unit: {{ reservation.vehicle.unit }}
        <button class="btn btn-primary ms-2 me-2" @click="openReservationWindow(reservation.vehicle)">Open</button>
        <button class="btn btn-primary" @click="releaseVehicle(reservation.vehicle)">Release</button>
      </div>
    </div>

    <div v-if="contactInfoVisible" class="modal" :class="{ show: contactInfoVisible }">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h3>Contact info</h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeContactInfo"></button>
          </div>
          <div class="modal-body">
            <p>
              Driver name: <span v-if="currentMainContact?.name" class="copy">{{ currentMainContact.name }}</span>
            </p>
            <p>
              Phone:
              <template v-for="phone of currentMainContact?.phone?.split(/[\/|\,]/gi).map(i => i.trim())">
                <span class="copy">{{ phone }}</span>
              </template>
            </p>

            <p>
              Equipment:
              <span v-if="currentVehicle?.equipments.length" class="copy">{{
                currentVehicle.equipments.map(i => i.equipment.name).join(', ')
              }}</span>
            </p>
            <p>
              Home location:
              <span v-if="currentMainContact?.homeLocation?.formattedAddress" class="copy">{{
                currentMainContact.homeLocation.formattedAddress || ''
              }}</span>
            </p>
            <p>
              Email:
              <template v-for="email of currentMainContact?.email?.split(/[\/|\,]/gi).map(i => i.trim())">
                <span class="copy ms-2" @click="e => copy(e.target.innerText)">{{ email }}</span>
              </template>
            </p>
            <p>TSA card: {{ currentMainContact.hasTsaCard ? 'Yes' : 'No' }}</p>
            <p>TWIC card: {{ currentMainContact.hasTwicCard ? 'Yes' : 'No' }}</p>
            <p>HAZMAT certificate: {{ currentMainContact.hasHazmatCert ? 'Yes' : 'No' }}</p>
            <p>Can cross the border: {{ currentMainContact?.canCrossBorder ? 'approved' : 'no approved' }}</p>
            <p>Can cross the canadian border: {{ currentMainContact?.canCrossCanadianBorder ? 'approved' : 'no approved' }}</p>
            <p>Can cross the mexican border: {{ currentMainContact?.canCrossMexicanBorder ? 'approved' : 'no approved' }}</p>
            <p>
              Company name:
              <span v-if="currentMainContact?.companyName" class="copy" @click="e => copy(e.target.innerText)">{{
                currentMainContact.companyName || ''
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="bidInfoVisible" class="modal" :class="{ show: bidInfoVisible }">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h3>Bid info</h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeBidInfo"></button>
          </div>
          <div class="modal-body">
            <pre class="copy" @click="e => copy(e.target.innerText)">
Hello!
        
I have following option:
Type of truck: {{ currentVehicle.type.name }}
Dimensions: {{ currentVehicle.length }}/{{ currentVehicle.width }}/{{ currentVehicle.height }} ({{ currentVehicle.doorWidth }}/{{
                currentVehicle.doorHeight
              }})
Capacity: {{ currentVehicle.capacity }} lbs
Equipment: {{ currentVehicle.equipments.map(i => i.equipment.name).join(', ') }}

{{ currentDistance }} miles out


Let me know please!</pre
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="reservationVisible" class="modal" :class="{ show: reservationVisible }">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h3>Reservation</h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeReservationWindow"></button>
          </div>
          <div class="modal-body">
            <p>Unit: {{ reservationVehicle.unit }}</p>
            <p>
              Status:
              {{
                !reservationVehicle.reservations.length
                  ? 'Available'
                  : `Currently reserved by (${reservationVehicle.reservations[0]?.account?.login})`
              }}
            </p>
            <template v-if="!getVehicleAvailability(reservationVehicle)">
              <p>
                Reserved from:
                {{
                  dateTimeWithSecondsFormatter.format(
                    new Date(reservationVehicle.reservations[0]?.updatedAt || reservationVehicle.reservations[0]?.createdAt),
                  )
                }}
              </p>
              <p>
                Reserved to:
                {{
                  dateTimeWithSecondsFormatter.format(
                    new Date(Math.max(...reservationVehicle.reservations.map(i => new Date(i.expiresAt).valueOf()))),
                  )
                }}
              </p>

              <div class="accordion mt-2 mb-2" id="accordion-queue">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="heading-queue">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-queue"
                      aria-expanded="false"
                      aria-controls="collapse-queue"
                    >
                      Reservation queue ({{ reservationVehicle.reservations.length }})
                    </button>
                  </h2>
                  <div
                    id="collapse-queue"
                    class="accordion-collapse collapse"
                    aria-labelledby="heading-queue"
                    data-bs-parent="#accordion-queue"
                  >
                    <div class="accordion-body">
                      <div v-for="(reservation, index) of reservationVehicle.reservations">
                        <p class="mb-0">{{ reservation.account.login }}</p>
                        <p class="mb-0">
                          From:
                          {{
                            dateTimeWithSecondsFormatter.format(new Date(reservation.updatedAt || reservation.createdAt))
                          }}
                        </p>
                        <p class="mb-0">
                          To:
                          {{
                            dateTimeWithSecondsFormatter.format(new Date(reservation.expiresAt))
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="getVehicleAvailability(reservationVehicle)">
              <button class="btn btn-primary" @click="reserveVehicle(reservationVehicle, 20)">Reserve for 20 minutes</button>
            </template>
            <template v-else-if="reservationVehicle.reservations.find(i => currentAccount.id === i.accountId)">
              <button class="btn btn-primary" @click="releaseVehicle(reservationVehicle)">Release</button>
              <template v-if="currentAccount.id !== reservationVehicle.reservations[reservationVehicle.reservations.length - 1]?.accountId">
                <button class="btn btn-primary ms-2" @click="reserveVehicle(reservationVehicle, 0)">Reserve after</button>
              </template>
            </template>
            <template v-else>
              <button class="btn btn-primary" @click="reserveVehicle(reservationVehicle, 20)">Reserve after</button>
            </template>

            <button class="btn btn-secondary ms-2" @click="removeVehicleFromService(reservationVehicle)">
              Remove from service
            </button>

            <div class="accordion mt-2" id="accordion-booking">
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading-booking">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-booking"
                    aria-expanded="false"
                    aria-controls="collapse-booking"
                  >
                    Booking
                  </button>
                </h2>
                <div
                  id="collapse-booking"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading-booking"
                  data-bs-parent="#accordion-booking"
                >
                  <div class="accordion-body">
                    <div class="mb-3 col-md-12">
                      <label for="currentVehicleLocation" class="form-label">Location</label>
                      <div class="d-flex flex-wrap align-items-center">
                        <SelectWithSearch
                          class="col-md-12"
                          :inputValue="bookLocation?.postalCode ?? bookLocation?.formattedAddress"
                          :items="bookLocations"
                          @search="onBookLocationSearch"
                          @clear="onBookLocationClear"
                        />
                        <span class="ms-3">{{ bookLocation?.formattedAddress }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="bookOnRouteTill" class="form-label">Date</label>
                      <input type="datetime-local" class="form-control col-md-12" id="bookOnRouteTill" v-model="bookOnRouteTill" />
                    </div>
                    <button class="btn btn-success" @click="bookVehicle(reservationVehicle)">Book</button>
                  </div>
                </div>
              </div>
            </div>

            <template v-if="currentAccount.id === reservationVehicle.reservations[0]?.accountId">
              <hr />
              <div class="mt-2">
                <label for="extendReservation" class="form-label">Keep reservation</label>
                <div class="input-group">
                  <input class="form-control" type="number" id="extendReservation" v-model="reservationDuration" />

                  <button
                    class="btn btn-primary input-group-append"
                    type="button"
                    @click="keepReservation(reservationVehicle, reservationDuration)"
                  >
                    Keep
                  </button>
                </div>
              </div>
            </template>

            <template v-if="myReservations.find(i => i.vehicleId === reservationVehicle.id)">
              <div class="mt-2">
                <label for="transferReservation" class="form-label">Transfer reservation to</label>
                <div class="input-group">
                  <SelectWithSearch
                    class="form-control reservation-account"
                    inputId="transferReservation"
                    :items="reservationAccounts"
                    @search="onReservationAccountSearch"
                    @clear="onReservationAccountClear"
                  ></SelectWithSearch>

                  <button
                    class="btn btn-primary input-group-append"
                    type="button"
                    @click="transferReservation(reservationVehicle, reservationAccount)"
                  >
                    Transfer
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateTimeWithSecondsFormatter, inputDateTimeToDate, isVehicleActiveByRules, debounce } from '@/utils';
import { personService } from '@/modules/person/person.service';
import {
  getVehicleTypeList,
  getVehicleList,
  getLocationList,
  getVehiclesInRadiusWithDistance,
  updateVehicle,
  reserveVehicle,
  releaseVehicle,
  bookVehicle,
  getAccountList,
  getMyReservationList,
  getEquipmentList,
} from '@/api';
import SelectWithSearch from '@/components/SelectWithSearch';
import MapSettings from '@/components/map/Settings.vue';

export default {
  name: 'MapPage',
  components: { SelectWithSearch, MapSettings },
  computed: {
    ...mapGetters(['vehicle/list', 'theme/name', 'theme/darkMode', 'account/current']),
    darkMode() {
      return this['theme/darkMode'];
    },
    currentAccount() {
      return this['account/current'];
    },
    themeName() {
      return this['theme/name'];
    },
  },
  data: () => ({
    mapService: null,

    markers: new Map(),

    myReservations: [],

    searchMode: 'location',

    vehicleTypes: [],
    vehicles: [],

    searchLocations: [],
    searchLocation: null,
    searchVehicles: [],
    searchVehicle: null,

    radius: localStorage.getItem('map-radius') || 300,
    radiusEditing: false,

    currentTime: new Date(),
    currentTimeInterval: null,

    currentVehicleDistances: null,
    isInfoOpened: false,
    isHintsOpened: false,
    isMyReservationsOpened: false,

    contactInfoVisible: false,
    bidInfoVisible: false,
    currentVehicle: null,
    currentMainContact: null,
    currentDistance: 0,

    reservationVisible: false,
    reservationVehicle: null,
    reservationDuration: 20,
    reservationAccounts: [],
    reservationAccount: null,

    bookLocations: [],
    bookLocation: null,
    bookOnRouteTill: null,

    mapSettings: null,

    getStatusTranslate: personService.getStatusTranslate,
    dateTimeWithSecondsFormatter,
  }),
  methods: {
    getVehicleTypeName(vehicleTypeId) {
      return this.vehicleTypes.find(i => i.id === vehicleTypeId)?.name || '-';
    },

    getVehicleTypeColor(vehicleTypeId) {
      const vehicleType = this.vehicleTypes.find(i => i.id === vehicleTypeId);
      if (vehicleType) {
        return vehicleType.color;
      }

      return '#000000';
    },

    getVehicleAvailability(vehicle) {
      if (!vehicle.reservations.length) {
        return true;
      }

      if (new Date(vehicle.reservations[0].expiresAt) <= this.currentTime) {
        return true;
      }

      return false;
    },

    getVehicleDriverStatus(vehicle) {
      const drivers = vehicle.contacts.filter(i => i.roles & 1);
      if (!drivers.length) {
        return this.getStatusTranslate('-');
      }

      const statuses = [...new Set(drivers.map(i => i.person?.status).filter(Boolean))];

      return statuses.map(i => this.getStatusTranslate(i)).join(', ');
    },

    changeSearchMode(mode) {
      this.searchMode = mode;
      this.currentVehicleDistances = null;

      setTimeout(() => document.querySelector('.map-header__search input.input')?.focus(), 100);
    },

    openInfo() {
      if (!this.currentVehicleDistances) {
        return;
      }

      this.isInfoOpened = true;
    },

    closeInfo() {
      this.isInfoOpened = false;
    },

    openHints() {
      this.isHintsOpened = true;
    },

    closeHints() {
      this.isHintsOpened = false;
    },

    openMyReservations() {
      if (!this.myReservations.length) {
        return;
      }

      this.isMyReservationsOpened = true;
    },

    closeMyReservations() {
      this.isMyReservationsOpened = false;
    },

    openContactInfo(vehicleWithDistance) {
      requestAnimationFrame(() => {
        const mainContact = vehicleWithDistance.vehicle.contacts.find(i => i.isMain);
        if (!mainContact) {
          this.$toast.error(`Can't get main contact`, 2000);
          return;
        }

        this.currentVehicle = vehicleWithDistance.vehicle;
        this.currentMainContact = mainContact.person;
        this.currentDistance = vehicleWithDistance.distance;
        this.contactInfoVisible = true;
      });
    },

    closeContactInfo() {
      requestAnimationFrame(() => {
        this.contactInfoVisible = false;
        this.currentVehicle = null;
        this.currentMainContact = null;
        this.currentDistance = 0;
      });
    },

    openBidInfo(vehicleWithDistance) {
      requestAnimationFrame(() => {
        const mainContact = vehicleWithDistance.vehicle.contacts.find(i => i.isMain);
        if (!mainContact) {
          this.$toast.error(`Can't get main contact`, 2000);
          return;
        }

        this.currentVehicle = vehicleWithDistance.vehicle;
        this.currentMainContact = mainContact.person;
        this.currentDistance = vehicleWithDistance.distance;
        this.bidInfoVisible = true;
      });
    },

    closeBidInfo() {
      requestAnimationFrame(() => {
        this.bidInfoVisible = false;
        this.currentVehicle = null;
        this.currentMainContact = null;
        this.currentDistance = 0;
      });
    },

    openReservationWindow(vehicle) {
      requestAnimationFrame(() => {
        this.reservationVehicle = vehicle;
        this.reservationVisible = true;
      });
    },

    closeReservationWindow() {
      requestAnimationFrame(() => {
        this.reservationVisible = false;
        this.reservationVehicle = null;

        this.bookLocations = [];
        this.bookLocation = null;
        this.bookOnRouteTill = null;
      });
    },

    async reserveVehicle(vehicle, duration) {
      const response = await reserveVehicle(vehicle.id, duration * 60);
      if (response.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      if (duration > 0) {
        this.$toast.success(`Vehicle was reserved for ${duration} minutes`, { duration: 2000 });
      } else {
        this.$toast.success(`Vehicle was reserved after last reservation`, { duration: 2000 });
      }
    },

    async releaseVehicle(vehicle) {
      const response = await releaseVehicle(vehicle.id);
      if (response.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      if (response.data.releaseVehicle[0]?.expiresAt) {
        this.$toast.success(`Vehicle was released`, { duration: 2000 });
      } else {
        this.$toast.success(`Vehicle reservation was canceled`, { duration: 2000 });
      }
    },

    async bookVehicle(vehicle, duration) {
      if (!this.bookLocation) {
        this.$toast.error(`Location is required`);
        return;
      }

      if (!this.bookOnRouteTill) {
        this.$toast.error(`Date is required`);
        return;
      }

      const response = await bookVehicle(
        vehicle.id,
        this.bookLocation?.id,
        this.bookOnRouteTill ? inputDateTimeToDate(this.bookOnRouteTill) : null,
      );
      if (response.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.$toast.success(`Vehicle was booked`, { duration: 2000 });
    },

    async removeVehicleFromService(vehicle) {
      const response = await updateVehicle(vehicle.id, { isInService: false });
      if (response.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.$toast.success(`Vehicle was removed from service`, { duration: 2000 });
    },

    async keepReservation(vehicle, duration) {
      const response = await reserveVehicle(vehicle.id, duration * 60);
      if (response.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.$toast.success(`Reservation kept to ${duration} minutes`, { duration: 2000 });
    },

    async transferReservation(vehicle, account) {
      if (!vehicle || !account) {
        return;
      }

      const response = await reserveVehicle(vehicle.id, 0, account.id);
      if (response.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.$toast.success(`Reservation was transferred to ${account.login}`, { duration: 2000 });
    },

    async initializeMap() {
      if (!this.mapService) {
        const searchParams = new URLSearchParams(location.search);
        const mapType = searchParams.get('map') || 'map-box';
        const { mapService } = await (mapType === 'map-box'
          ? import('@/modules/map/map-box.service')
          : import('@/modules/map/map.service'));

        this.mapService = mapService;
      }

      await this.mapService.initMap({
        mapElement: this.$refs.googleMap,
        theme: this.themeName,
      });

      this.mapService.updateMarkers();
    },

    async updateVehicleTypes() {
      try {
        const response = await getVehicleTypeList();
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.vehicleTypes = response.data.vehicleTypes;
      } catch (err) {
        console.error(err);
        this.$toast.error(err.message);
      }
    },

    async updateVehicles() {
      try {
        const response = await getVehicleList(undefined, undefined, null, null, false, { active: true, inService: true });
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.vehicles = response.data.vehicles;
        this.vehicles.forEach(i =>
          this.mapService.createVehicleMarker(i, this.getVehicleTypeName(i.typeId), this.getVehicleTypeColor(i.typeId)),
        );
      } catch (err) {
        console.error(err);
        this.$toast.error(err.message);
      }
    },

    async onReservationAccountSearch(search) {
      const response = await getAccountList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.reservationAccounts = response.data.accounts
        .filter(i => i.id !== this.currentAccount.id)
        .map(i => ({
          title: i.login,
          onClick: () => this.setReservationAccount(i),
        }));
    },

    onReservationAccountClear() {
      this.reservationAccounts = [];
      this.setReservationAccount(null);
    },

    setReservationAccount(account) {
      this.reservationAccount = account;
    },

    async onBookLocationSearch(search) {
      const response = await getLocationList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.bookLocations = response.data.locations.map(i => ({
        title: i.formattedAddress,
        onClick: () => this.setBookLocation(i),
      }));
    },

    onBookLocationClear() {
      this.bookLocations = [];
      this.setBookLocation(null);
    },

    setBookLocation(location) {
      this.bookLocation = location;
    },

    async onSearchLocationSearch(search) {
      if (!search && this.mapSettings.saveSearchHistory) {
        try {
          const history = JSON.parse(localStorage.getItem('map-search-location-history'));

          this.searchLocations = history.map(i => ({
            title: i.formattedAddress,
            onClick: () => this.setSearchLocation(i),
          }));

          return;
        } catch (err) {}
      }

      const response = await getLocationList(100, 0, search);
      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.searchLocations = response.data.locations.map(i => ({
        title: i.formattedAddress,
        onClick: () => this.setSearchLocation(i),
      }));

      const el = document.querySelector('.info-window');
      if (el) {
        el.scrollTop = 0;
      }
    },

    onSearchLocationClear() {
      this.searchLocations = [];
      this.setSearchLocation(null);
      this.currentVehicleDistances = null;
    },

    async setSearchLocation(location) {
      this.searchLocation = location;

      {
        if (this.mapSettings.saveSearchHistory && location) {
          let history;

          try {
            history = JSON.parse(localStorage.getItem('map-search-location-history'));
          } catch (err) {}

          if (!history) {
            history = [];
          }

          const indexInHistory = history.findIndex(i => i.id === location.id);
          if (indexInHistory !== -1) {
            history[indexInHistory].searchedAt = new Date();
          } else {
            history.push({ ...location, searchedAt: new Date() });
          }

          localStorage.setItem(
            'map-search-location-history',
            JSON.stringify(history.sort((a, b) => new Date(b.searchedAt) - new Date(a.searchedAt)).slice(0, 10)),
          );
        }
      }

      if (!this.searchLocation) {
        this.mapService.clearPointMarkers();
        return;
      }

      this.fetchVehiclesInRadius();

      this.mapService.createPointMarker({ lat: location.lat, lng: location.lng }, this.radius);
    },

    async fetchVehiclesInRadius() {
      const { selectedEquipments, selectedVehicleTypes, selectedResidentStatuses } = this.mapSettings;

      const response = await getVehiclesInRadiusWithDistance(this.searchLocation.id, this.radius * 1000 * 1.609, {
        equipments: selectedEquipments.length ? selectedEquipments : undefined,
        types: selectedVehicleTypes.length ? selectedVehicleTypes : undefined,
        residentStatuses: selectedResidentStatuses.length ? selectedResidentStatuses : undefined,
      });
      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.currentVehicleDistances = response.data.vehiclesInRadiusWithDistance
        .filter(i => !!i.vehicle)
        .map(i => ({
          ...i,
          distance: (i.distance / 1000 / 1.609) | 0,
        }))
        .sort((a, b) => a.distance - b.distance);

      this.isInfoOpened = true;
    },

    async onSearchVehicleSearch(search) {
      const response = await getVehicleList(100, 0, search, [{ field: 'unit', order: 'ASC' }], undefined, undefined, ['unit']);
      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.searchVehicles = response.data.vehicles.map(i => ({
        title: i.unit,
        onClick: () => this.setSearchVehicle(i),
      }));
    },

    onSearchVehicleClear() {
      this.searchVehicles = [];
      this.setSearchVehicle(null);
      this.currentVehicleDistances = null;
    },

    async setSearchVehicle(vehicle) {
      this.searchVehicle = vehicle;

      if (!this.searchVehicle) {
        this.mapService.clearPointMarkers();
        return;
      }

      this.currentVehicleDistances = [
        {
          vehicle: this.searchVehicle,
          distance: 0,
        },
      ];
      setTimeout(() => (this.isInfoOpened = true), 0);

      if (vehicle.location) {
        this.mapService.createPointMarker({ lat: vehicle.location.lat, lng: vehicle.location.lng }, this.radius);
      }
    },

    onRadiusChange(event) {
      this.setRadius(event.target.value);
    },

    onRadiusClick() {
      this.radiusEditing = true;

      if (this.radiusEditing) {
        setTimeout(() => this.$refs.radiusInput?.focus(), 0);
      }
    },

    onRadiusBlur() {
      this.radiusEditing = false;
      this.setSearchLocation(this.searchLocation);
    },

    async setRadius(radius) {
      this.radius = radius;
      localStorage.setItem('map-radius', this.radius);
    },

    async updateVehicleNotes(vehicle, event) {
      try {
        const notes = event.target.value;
        if (vehicle.notes === notes) {
          return;
        }

        const response = await updateVehicle(vehicle.id, { notes });
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Vehicle notes were updated.', { duration: 2000 });
      } catch (err) {
        console.error(err);
        this.$toast.error(err.message);
      }
    },

    clickOutside(event) {
      if (event.target.closest('.modal')) {
        return;
      }

      if (event.target.closest('.v-sidebar-menu')) {
        return;
      }

      if (this.isInfoOpened) {
        if (event.target.closest('.info-label')) {
          return;
        }

        if (!event.target.closest('.info-window')) {
          this.closeInfo();
        }
      }

      if (this.isMyReservationsOpened) {
        if (event.target.closest('.my-reservations-label')) {
          return;
        }

        if (!event.target.closest('.my-reservations')) {
          this.closeMyReservations();
        }
      }
    },

    keyDown(event) {
      if (this.radiusEditing) {
        if (event.key === 'Escape' || event.key === 'Enter') {
          this.radiusEditing = false;
        }
      }

      if (event.target.closest('.notes')) {
        if (event.key === 'Escape' || (event.key === 'Enter' && !event.shiftKey)) {
          event.target.blur();
        }
      }
    },

    loadMyReservations() {
      getMyReservationList().then(response => {
        this.myReservations = response?.data?.myReservations || [];
      });
    },

    copy(text) {
      var textArea = document.createElement('textarea');
      textArea.value = text;

      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        if (document.execCommand('copy')) {
          this.$toast.success(`Copied`);
        }
      } catch (err) {
        this.$toast.error(err.message);
      }

      document.body.removeChild(textArea);
    },

    onMapSettingsChange(settings) {
      this.mapSettings = settings;

      if (this.searchLocation) {
        this.fetchVehiclesInRadius();
      }
    },
  },
  created() {
    this.fetchVehiclesInRadius = debounce(this.fetchVehiclesInRadius.bind(this), 500);

    this.$store.subscribe(mutation => mutation.type === 'vehicleType/ADD_TO_LIST' && this.updateVehicleTypes());

    this.loadMyReservations();
  },
  async mounted() {
    await this.initializeMap();
    await this.updateVehicleTypes();
    await this.updateVehicles();

    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('click', this.clickOutside);

    this.currentTime = new Date();
    this.currentTimeInterval = setInterval(() => {
      this.currentTime = new Date();
    }, 1000);

    this.storeSubscription = this.$store.subscribe(async (mutation, state) => {
      switch (mutation.type) {
        case 'vehicle/ADD_TO_LIST':
          const vehicle = mutation.payload;

          this.vehicles?.push(vehicle);
          this.mapService.createVehicleMarker(vehicle, this.getVehicleTypeName(vehicle.typeId), this.getVehicleTypeColor(vehicle.typeId));

          break;

        case 'vehicle/UPDATE_IN_LIST': {
          const vehicle = mutation.payload;

          if (!vehicle.isActive || !vehicle.isInService || !isVehicleActiveByRules(vehicle)) {
            {
              const vehicleIIndex = this.vehicles?.findIndex(i => i.id === vehicle.id) ?? -1;
              if (vehicleIIndex !== -1) {
                this.vehicles.splice(vehicleIIndex, 1);
              }
            }

            {
              const vehicleIIndex = this.currentVehicleDistances?.findIndex(i => i.vehicle.id === vehicle.id)?.vehicle ?? -1;
              if (vehicleIIndex !== -1) {
                this.currentVehicleDistances.splice(vehicleIIndex, 1);
              }
            }

            {
              if (vehicle.id === this.reservationVehicle?.id) {
                this.closeReservationWindow();
              }
            }

            this.mapService.removeVehicleMarker(vehicle);
          }

          break;
        }

        case 'vehicle/REMOVE_FROM_LIST': {
          const vehicle = mutation.payload;

          {
            const vehicleIIndex = this.vehicles?.findIndex(i => i.id === vehicle.id) ?? -1;
            if (vehicleIIndex !== -1) {
              this.vehicles.splice(vehicleIIndex, 1);
            }
          }

          {
            const vehicleIIndex = this.currentVehicleDistances?.findIndex(i => i.vehicle.id === vehicle.id)?.vehicle ?? -1;
            if (vehicleIIndex !== -1) {
              this.currentVehicleDistances.splice(vehicleIIndex, 1);
            }
          }

          {
            if (vehicle.id === this.reservationVehicle?.id) {
              this.closeReservationWindow();
            }
          }

          this.mapService.removeVehicleMarker(vehicle);

          break;
        }

        case 'person/UPDATE_IN_LIST': {
          const person = mutation.payload;

          this.vehicles?.forEach(i => {
            const contact = i.contacts.find(j => j.personId === person.id);
            if (!contact) {
              return;
            }

            contact.person = person;
          });

          this.currentVehicleDistances?.forEach(i => {
            const contact = i.vehicle.contacts.find(j => j.personId === person.id);
            if (!contact) {
              return;
            }

            contact.person = person;
          });

          break;
        }

        case 'equipment/UPDATE_IN_LIST': {
          const equipment = mutation.payload;

          this.vehicles?.forEach(i => {
            const vehicleEquipment = i.equipments.find(j => j.equipmentId === equipment.id);
            if (!vehicleEquipment) {
              return;
            }

            vehicleEquipment.equipment = equipment;
          });

          this.currentVehicleDistances?.forEach(i => {
            const vehicleEquipment = i.vehicle.equipments.find(j => j.equipmentId === equipment.id);
            if (!vehicleEquipment) {
              return;
            }

            vehicleEquipment.equipment = equipment;
          });

          break;
        }

        case 'vehicleReservation/ADD_TO_LIST':
        case 'vehicleReservation/UPDATE_IN_LIST': {
          const reservation = mutation.payload;

          {
            const vehicle = this.vehicles?.find(i => i.id === reservation.vehicleId);
            if (vehicle) {
              const reservationIndex = vehicle.reservations.findIndex(i => i.id === reservation.id);
              if (reservationIndex !== -1) {
                vehicle.reservations[reservationIndex] = reservation;
              } else {
                vehicle.reservations.push(reservation);
              }
            }
          }

          {
            const vehicle = this.currentVehicleDistances?.find(i => i.vehicle.id === reservation.vehicleId)?.vehicle;
            if (vehicle) {
              const reservationIndex = vehicle.reservations.findIndex(i => i.id === reservation.id);
              if (reservationIndex !== -1) {
                vehicle.reservations[reservationIndex] = reservation;
              } else {
                vehicle.reservations.push(reservation);
              }
            }
          }

          {
            const vehicle = this.reservationVehicle;
            if (vehicle && vehicle.id === reservation.vehicleId) {
              const reservationIndex = vehicle.reservations.findIndex(i => i.id === reservation.id);
              if (reservationIndex !== -1) {
                vehicle.reservations[reservationIndex] = reservation;
              } else {
                vehicle.reservations.push(reservation);
              }
            }
          }

          {
            const reservationIndex = this.myReservations?.findIndex(i => i.id === reservation.id) ?? -1;

            if (reservationIndex !== -1) {
              if (reservation.accountId === this.currentAccount.id) {
                const prevReservation = this.myReservations[reservationIndex];
                this.myReservations[reservationIndex] = reservation;

                if (!prevReservation.expiresAt && reservation.expiresAt) {
                  this.$toast.success(`Vehicle ${reservation.vehicle.unit} was reserved`, { duration: 2000 });
                }
              } else {
                this.myReservations.splice(reservationIndex, 1);
              }
            } else if (reservation.accountId === this.currentAccount.id) {
              this.myReservations.push(reservation);
            }
          }

          break;
        }

        case 'vehicleReservation/REMOVE_FROM_LIST': {
          const reservation = mutation.payload;

          {
            const vehicle = this.vehicles.find(i => i.id === reservation.vehicleId);
            if (vehicle) {
              const reservationIndex = vehicle.reservations.findIndex(i => i.id === reservation.id);
              if (reservationIndex !== -1) {
                vehicle.reservations.splice(reservationIndex, 1);
              }
            }
          }

          {
            const vehicle = this.currentVehicleDistances?.find(i => i.vehicle.id === reservation.vehicleId)?.vehicle;
            if (vehicle) {
              const reservationIndex = vehicle.reservations.findIndex(i => i.id === reservation.id);
              if (reservationIndex !== -1) {
                vehicle.reservations.splice(reservationIndex, 1);
              }
            }
          }

          {
            const vehicle = this.reservationVehicle;
            if (vehicle && vehicle.id === reservation.vehicleId) {
              const reservationIndex = vehicle.reservations.findIndex(i => i.id === reservation.id);
              if (reservationIndex !== -1) {
                vehicle.reservations.splice(reservationIndex, 1);
              }
            }
          }

          {
            const reservationIndex = this.myReservations.findIndex(i => i.id === reservation.id);
            if (reservationIndex !== -1) {
              this.myReservations.splice(reservationIndex, 1);
            }
          }

          break;
        }

        default:
          break;
      }
    });
  },
  unmounted() {
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('click', this.clickOutside);

    clearInterval(this.currentTimeInterval);

    this.storeSubscription?.();
  },
  watch: {
    themeName() {
      this.initializeMap();
    },
  },
};
</script>

<style scoped>
.map-header {
  --global-border-radius: 1rem;

  position: absolute;
  margin-top: 2em;
  padding: 0 2em;

  width: 100%;
  z-index: 100;
}

.map-header__search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.map-header__button {
  width: 18%;
  padding: 0.8em;
  background: var(--primary-background-color);
  border-radius: var(--global-border-radius);
  box-shadow: 0 0.1em 0.8em -0.1em rgba(0, 0, 0, 0.8);
  text-align: center;
  color: var(--primary-text-color);
  cursor: pointer;
}

.map-settings {
  width: 18%;
}

.map-header__button.disabled {
  color: var(--disabled-text-color);
  cursor: default;
}

.map-header__input {
  appearance: none;
  background: inherit;
  color: inherit;
  border: none;
  outline: none;
  width: 100%;
}

.map-header__search .search {
  width: 60%;
  border-radius: var(--global-border-radius);
  box-shadow: 0 0.1em 0.8em -0.1em rgba(0, 0, 0, 0.8);
}

.map-header__search .search-mode {
  position: absolute;
  top: 0;
  left: 0;

  width: 2.68em;
  height: 100%;

  border-top-left-radius: var(--global-border-radius);
  border-bottom-left-radius: var(--global-border-radius);
}

.map-header__timezones {
  margin-top: 1em;
  margin-left: 25%;
  width: 50%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timezone {
  padding: 0.4em 1.2em;
  background: var(--primary-background-color);
  border-radius: var(--global-border-radius);
  box-shadow: 0 0.1em 0.8em -0.1em rgba(0, 0, 0, 0.8);
  text-align: center;
  color: var(--primary-text-color);
  cursor: default;
}

.map-page,
.google-map {
  height: 100vh;
  position: relative;
}

.info-label {
  position: absolute;
  left: 0;
  top: 16%;
  background: var(--primary-background-color);
  color: var(--primary-text-color);
  user-select: none;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  padding: 1em 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 var(--global-border-radius) var(--global-border-radius) 0;
  transition: all 0.5s;
  cursor: pointer;
}

.info-label.opened {
  left: calc(75% - 1px);
}

.info-window {
  position: absolute;
  left: -1px;
  top: 16%;
  width: 75%;
  background: var(--primary-background-color);
  color: var(--primary-text-color);
  padding: 0.6em 0.6em 0 0.6em;
  border-radius: 0 0 var(--global-border-radius) 0;
  min-height: 20%;
  max-height: 80%;
  overflow-y: auto;
  user-select: text;
  z-index: 101;
  transition: all 0.5s;
}

.info-window:not(.opened) {
  transform: translateX(-100%);
}

.info-section {
  display: flex;
  flex-direction: row;
  padding: 0.6em;
  font-size: 0.6rem;
}

.info-section:nth-child(odd) {
  background-color: var(--dispatch-odd-color);
}

.info-section:nth-child(even) {
  background-color: var(--dispatch-even-color);
}

.info-section:last-child {
  margin-bottom: 0;
}

.block {
  display: grid;
  grid-gap: 0.6em;
  margin: 0 0.4em;
}

.block > * {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2em;
  overflow: hidden;
}

.block-1 {
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, auto);
  width: 28%;
}

.block-1 .turbo-logo img,
.block-1 .mmm-logo img {
  width: 4.8em;
}

.block-1 .turbo-logo {
  grid-row: 1;
  grid-column: 1;
}

.block-1 .mmm-logo {
  grid-row: 1;
  grid-column: 2;
}

.block-1 .unit {
  grid-row: 1;
  grid-column: 3 / 6;
  color: #fff;
  cursor: pointer;
}

[data-theme='default'] .block-1 .unit.available {
  background-color: var(--sidebar-item-color);
}

[data-theme='dark'] .block-1 .unit.available {
  background-color: var(--sidebar-item-active-color);
}

.block-1 .unit.unavailable {
  background-color: rgb(150, 0, 0);
}

.block-1 .contact-lang {
  grid-row: 2;
  grid-column: 1 / 3;
}

.block-1 .contact-name {
  grid-row: 2;
  grid-column: 3 / 6;
}

.block-1 .contact-status {
  grid-row: 3;
  grid-column: 1 / 3;
}

.block-1 .driver-status {
  grid-row: 3;
  grid-column: 1 / 3;
}

.block-1 .contact-phone {
  grid-row: 3;
  grid-column: 3 / 6;
}

.block-1 .notes {
  grid-row: 4;
  grid-column: 1 / 6;
}

.block-1 .notes textarea {
  width: 100%;
  height: 100%;
  padding: 0 0.4em;
  background: inherit;
  color: inherit;
}

.block-2 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, auto);
  width: 16%;
}

.block-2 .type {
  grid-row: 1;
}

.block-2 .dimensions {
  grid-row: 2;
}

.block-2 .capacity {
  grid-row: 3;
}

.block-2 .team {
  grid-row: 4;
}

.block-3 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 14%;
}

.block-4 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, auto);
  width: 22%;
}

.block-4 .available-from.red {
  background-color: #dcadad;
}

.block-5 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, auto);
  width: 14%;
}

.block-5 .equipment-1 {
  grid-row: 1;
}

.block-5 .equipment-2 {
  grid-row: 2;
}

.block-5 .equipment-3 {
  grid-row: 3;
}

.block-5 .equipment-4 {
  grid-row: 4;
}

.block-6 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1.8em;
  padding: 2em 0;
}

.block-6 > * {
  width: 2.8em;
  height: 2.8em;
  margin: auto;
  cursor: pointer;
}

.block-6 .driver-info {
  grid-row: 1;
}

.block-6 .bid {
  grid-row: 2;
}

.hints-label {
  position: absolute;
  right: 0;
  bottom: 10%;
  background: var(--primary-background-color);
  color: var(--primary-text-color);
  user-select: none;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  padding: 1em 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 var(--global-border-radius) var(--global-border-radius) 0;
  transition: all 0.5s;
  transform: rotate(-180deg);
  cursor: pointer;
}

.hints-label.opened {
  right: calc(25% - 1px);
}

.hints {
  position: absolute;
  background: var(--primary-background-color);
  color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  bottom: 10%;
  right: 0;
  padding: 1em 1.6em;
  width: 25%;
  min-height: 8em;
  max-height: 40%;
  overflow-y: auto;
  border-radius: var(--global-border-radius) 0 0 0;
  transition: all 0.5s;
}

.hints:not(.opened) {
  transform: translateX(100%);
}

.hint {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hint i {
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  margin-right: 0.4em;
}

.copy {
  background: var(--secondary-background-color);
  padding: 0.2em;
  user-select: all;
}

.my-reservations-label {
  position: absolute;
  left: 0;
  top: 36%;
  background: var(--primary-background-color);
  color: var(--primary-text-color);
  user-select: none;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  padding: 1em 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 var(--global-border-radius) var(--global-border-radius) 0;
  transition: all 0.5s;
  cursor: pointer;
}

.my-reservations-label.opened {
  left: calc(30% - 1px);
}

.my-reservations {
  position: absolute;
  left: -1px;
  top: 36%;
  width: 30%;
  background: var(--primary-background-color);
  color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  bottom: 10%;
  right: 0;
  padding: 1em 1.6em;
  min-height: 8em;
  max-height: 20%;
  overflow-y: auto;
  border-radius: 0 0 var(--global-border-radius) 0;
  transition: all 0.5s;
}

.my-reservations:not(.opened) {
  transform: translateX(-100%);
}

#collapse-queue .accordion-body {
  padding: 0;
}

#collapse-queue .accordion-body > * {
  padding: 0.5rem 1.25rem;
}

#collapse-queue .accordion-body > *:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.25);
}

[data-theme='dark'] #collapse-queue .accordion-body > *:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.25);
}
</style>

<style>
.map-header__search .search input {
  padding-top: 0.8em !important;
  padding-bottom: 0.8em !important;
}

.reservation-account.form-control {
  padding: 0;
  border: 0;
}

.reservation-account.form-control .input-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: var(--primary-background-color);
}

.mapboxgl-popup-content {
  background: var(--primary-background-color);
  color: var(--primary-text-color);
}

.mapboxgl-popup-close-button {
  color: var(--primary-text-color);
}
</style>
